import { Buttons } from "@/ui/Buttons";
import { Tagline } from "@/ui/Tagline";
import cn from "classnames";
import { Card, Container, Ratio } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";

import bg_1 from "./bg_1.webp";
import classes from "./styles.module.scss";

export default function ({
  className,
  tagline,
  title,
  description,
  image = null,
  video = null,
  buttons,
  EditableText,
  EditableHtml,
  EditableImage,
  children,
  theme = "light",
}) {
  return (
    <Card className={cn(className, classes.Hero)} data-bs-theme={theme}>
      <Parallax easing="easeInBack" translateY={[0, 50]} className="parallax">
        {!image && !video && <img src={bg_1} className={classes.bg_1} alt="" />}
        <Card.Img as={Ratio} aspectRatio={video ? 60 : 34}>
          {video ? (
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              poster={image ?? "/assets/default_hero.webp"}
            >
              {video && <source src={video} type="video/mp4" />}
            </video>
          ) : (
            <EditableImage
              path="image"
              src={image ?? "/assets/default_hero.webp"}
              size="1920x547"
            />
          )}
        </Card.Img>
      </Parallax>
      <Card.ImgOverlay className="d-flex flex-column text-center justify-content-center pb-4 pb-md-5 pt-5">
        <Container fluid="md" className="mw-lg pt-5 pb-md-5">
          <div className="mb-3">
            <Tagline as={EditableText} tag="strong" path="tagline" className="text-dark">
              <span dangerouslySetInnerHTML={{ __html: tagline }} />
            </Tagline>
            <div>
              <Card.Title as={EditableHtml} path="title" tag="h1">
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Card.Title>
            </div>
            <Card.Text as={EditableHtml} path="description" tag="div">
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </Card.Text>
          </div>
          {children}
          {buttons && (
            <Buttons buttons={buttons} className="d-flex flex-row justify-content-center gap-3" />
          )}
        </Container>
      </Card.ImgOverlay>
    </Card>
  );
}
